/* eslint-disable prettier/prettier */
import { useState, createContext, useContext, useEffect } from "react";
import { useMoralis } from "react-moralis";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) =>{

    const [loggedInUser, setLoggedInUser] = useState(null);
    //const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));

    const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
    const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

    const {Moralis , logout} = useMoralis();
    Moralis.start({ serverUrl: SERVER_URL, appId: APP_ID });

    const authenticateUser = async(request) =>{
        try {
        const user = await Moralis.User.logIn(request.email, request.password, { usePost: true });
        setLoggedInUser(user.attributes);
        
        console.log(user);
        return user;
        } catch (error) {
            console.log(error);
            throw error;
        }
        
    }

    const adminSignup = async() =>{
        const user = new Moralis.User();
        user.set("username", "Admin");
        user.set("password", "Admin@123");
        user.set("email", "admin@hunycomb.io");

        // other fields can be set just like with Moralis.Object
        user.set("phone", "415-392-0202");
        try {
        await user.signUp();
        console.log('Signup Done');
        // Hooray! Let them use the app now.
        } catch (error) {
        // Show the error message somewhere and let the user try again.
        alert("Error: " + error.code + " " + error.message);
        }
    }

    const isLoggedIn = () =>{
        const currentUser =  Moralis.User.current();
        console.log(currentUser);
        if (currentUser) {
            // do stuff with the user
            setLoggedInUser(currentUser?.attributes);
            return true;
        } else {
            // show the signup or login page
            return false;
        }
    }

    // const updateUserInfo = user =>{
    //     localStorage.setItem('userInfo', JSON.stringify(user));
    //     setUser(user);
    //     setUserInfo(user);
    // }

    const signOut = async() =>{
        await logout();
        setLoggedInUser(null);
        Moralis.User.logOut();
    }
    
    useEffect(() =>{
        //adminSignup();
        
        isLoggedIn()
      },[])

    return <AuthContext.Provider value={{loggedInUser,signOut, isLoggedIn, authenticateUser}} >{children}</AuthContext.Provider>
}

export const useAuth = () =>{
    return useContext(AuthContext);
}