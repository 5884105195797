/* eslint-disable prettier/prettier */
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { MoralisProvider } from "react-moralis";
import { AuthProvider } from 'Context/AuthContext';
import { PortalContextProvider } from 'Context/PortalContext';

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
       <MoralisProvider serverUrl={SERVER_URL} appId={APP_ID}>
        <AuthProvider>
            <PortalContextProvider>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
        </PortalContextProvider>
        </AuthProvider>
        </MoralisProvider>
    );
};

export default App;
