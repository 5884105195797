// assets
import { IconDashboard, IconKey, IconLock } from '@tabler/icons';
// constant
const icons = { IconDashboard, IconKey, IconLock };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'create-vesting',
            title: 'Create Vesting',
            type: 'item',
            url: '/create-vesting',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'track-vesting',
            title: 'Track Vesting',
            type: 'item',
            url: '/track-vesting',
            icon: icons.IconLock,
            breadcrumbs: true
        },
        {
            id: 'daos',
            title: 'DAOs',
            type: 'item',
            url: '/dashboard/default2',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'staking',
            title: 'Staking',
            type: 'item',
            url: '/dashboard/default2',
            icon: icons.IconDashboard,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
