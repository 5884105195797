/* eslint-disable prettier/prettier */
import { Navigate } from "react-router-dom";
import { useAuth } from "Context/AuthContext";

export const RequireAuth = ({children}) =>{
    const {isLoggedIn, loggedInUser} = useAuth();
    if(!loggedInUser){
        return <Navigate to='/login' />
    }
    return children;
}