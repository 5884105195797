/* eslint-disable prettier/prettier */

import { useState, createContext, useContext, useEffect } from "react";
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
//import axios from 'axios'
import {useMoralis, useMoralisWeb3Api } from "react-moralis";

import { tokenVestingAddress,hunyCombTokenAddress } from "smartContractConfig.js";

import TokenVesting from '../ABI/TokenVesting.json'
import HunyCombToken from '../ABI/HunyCombToken.json'

import { useMoralisQuery } from "react-moralis";

const PortalContext = createContext(null);

const NODE_URL = "https://matic-mumbai.chainstacklabs.com";

export const PortalContextProvider = ({ children }) => {

  const[loadingState, setLoadingState] = useState('')
  const[vestingSchedules, setVestingSchedules] = useState([])

  const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;
  const ENV = process.env.REACT_APP_ENVIRONMENT;
 

  const Web3Api = useMoralisWeb3Api();
  const { Moralis,} = useMoralis();
  Moralis.start({ serverUrl: SERVER_URL, appId: APP_ID });

  async function getAllVestings(){
    const query = new Moralis.Query('VestingSchedule');
    //query.equalTo("username", userName)
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setVestingSchedules(res);
                //updateUserInfo(user);
            }
            
        })
    } catch (error) {
        console.log(error);
        throw error;
    }
    
  }

  async function createVestingSchedule(request) {
    try {
    setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(tokenVestingAddress, TokenVesting.abi, signer);

      //const price = ethers.utils.parseUnits(nft.price.toString(), 'ether');

        // address _beneficiary,
        // uint256 _start,
        // uint256 _cliff,
        // uint256 _duration,
        // uint256 _slicePeriodSeconds,
        // bool _revocable,
        // uint256 _amount
        const startOn = Math.floor(Date.now() / 1000);
        console.log('request', request, 'startOn', startOn);
      const transaction = await contract.createVestingSchedule(
        ethers.utils.getAddress(request.beneficiaryAddress),
        startOn,
        request.cliffMonth,
        request.lockDuration,
        request.everyLockIn,
        true,
        request.lockAmount
        )

      await transaction.wait();

    //   setMessage('success')
      setLoadingState('loaded');

      console.log('success');

    //   getPurchasedItems();
    //   getUnsoldMarketItems();

    } catch (error) {
      console.log(error);
      //setError(error.message);
       setLoadingState('loaded')
    //   setMessage('')
    //   throw error.message;
    }
  }


  useEffect(() => {
    getAllVestings();
    
  }, [])

  return <PortalContext.Provider value={{
    createVestingSchedule,
    loadingState,
    vestingSchedules,
    getAllVestings
    
  }} >{children}</PortalContext.Provider>
}

export const usePortalContext = () => {
  return useContext(PortalContext);
}